<template>
  <div>
    <slot
      name="actionTrigger"
      :onClickOnActionTrigger="onClickOnActionTrigger"
    />
    <b-modal
      :ref="'tipsCreateFromLastRecord'"
      scrollable
      no-close-on-backdrop
      content-class="shadow"
      title="Créer un formulaire"
      size="lg"
    >
      <!-- <b-card-text>
        <span>We've added the utility class</span>
        <code>'shadow'</code>
        <span>to the modal content for added effect.</span>
      </b-card-text> -->
      <b-row>
        <b-col cols="12">
          <b-card>
            <h3 class="text-primary">
              Créer a partir du dernier formulaire enregistré
            </h3>
            <p class="my-1">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Voluptatum numquam corrupti dolorum quidem, nihil cupiditate
              optio, aspernatur iste pariatur eius doloribus illum quo, suscipit
              officiis quae unde assumenda corporis odio?
            </p>
            <b-button
              variant="outline-primary"
              class="float-right"
              @click="createFromLastRecord"
            >
              Continuer
            </b-button>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-card>
            <h3 class="text-primary">
              Créer a partir d'un formulaire vierge
            </h3>
            <p class="my-1">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Voluptatum numquam corrupti dolorum quidem, nihil cupiditate
              optio, aspernatur iste pariatur eius doloribus illum quo, suscipit
              officiis quae unde assumenda corporis odio?
            </p>

            <b-button
              variant="outline-primary"
              class="float-right"
            >
              Continuer
            </b-button>
          </b-card>
        </b-col>
      </b-row>

      <template #modal-footer>
        <div class="mt-1">
          <b-form-checkbox
            id="checkbox-1"
            v-model="dontShowAgain"
            name="checkbox-1"
            value="accepted"
            unchecked-value="not_accepted"
          >
            Je ne veux plus voir ça
          </b-form-checkbox>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BModal,
  VBModal,
  BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapState } from 'vuex'

export default {
  components: {
    BCard,

    BRow,
    BCol,
    BButton,
    BModal,
    BFormCheckbox,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    selectedFormInfos: {
      type: Object,
      default: () => null,
    },
    showBefore: {
      type: Boolean,
      default: false,
    },
    showAfter: {
      type: Boolean,
      default: false,
    },
    lastRecordedForm: {
      type: Object,
      default: () => null,
    },
    canShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { dontShowAgain: true }
  },
  computed: {
    // lastRecordedForm() {
    //   const { lastRecord } = this.currentUser
    //   if (!lastRecord) return null
    //   return lastRecord
    // },
  },
  watch: {
    // canShow: {
    //   immediate: true,
    //   handler(val, old) {
    //     // // console.log(val)
    //     // if (val === false) return this.hideModal()
    //     return this.showModal()
    //   },
    // },
  },
  methods: {
    ...mapState('auht', {
      currentUser: 'user',
    }),
    onClickOnActionTrigger() {
      // console.log('selected', this.lastRecordedForm)
      if (!this.selectedFormInfos) return false
      if (!this.lastRecordedForm) return this.createNewForm()

      return this.showModal()
    },
    createNewForm() {
      this.$router.push({
        ...this.selectedFormInfos.createRouteProps,
      })
    },
    createFromLastRecord() {
      this.hideModal()
      const { params } = this.selectedFormInfos.createRouteProps
      this.$router.push({
        ...this.selectedFormInfos.createRouteProps,
        params: {
          ...params,
          ...(this.lastRecordedForm && { data: this.lastRecordedForm }),
        },
      })
    },
    showModal() {
      this.$refs.tipsCreateFromLastRecord.show()
    },
    hideModal() {
      this.$refs.tipsCreateFromLastRecord.hide()
    },
  },
}
</script>

import etablissementsService from '@/services/http/etablissements.service'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getListTypesEtablissement({ rootState }, data) {
      const res = await etablissementsService.getListTypesEtablissement(data)
      return res
    },
    async getEtablissement({ rootState }, data) {
      const res = await etablissementsService.getEtablissement(data)
      return res
    },

    async fetchEtablissements({ rootState }, data) {
      const res = await etablissementsService.fetchEtablissements(data)
      return res
    },
    async exportEtablissements({ rootState }, data) {
      const res = await etablissementsService.exportEtablissements(data)
      return res
    },
    async findEtablissement({ rootState }, data) {
      const res = await etablissementsService.findEtablissement(data)
      return res
    },
    async createEtablissement({ rootState }, data) {
      const res = await etablissementsService.createEtablissement(data)
      return res
    },
    async verifyEtablissementEmail({ rootState }, data) {
      const res = await etablissementsService.verifyEtablissementEmail(data)
      return res
    },
    async validateInscriptionEtablissement({ rootState }, data) {
      const res = await etablissementsService.validateInscriptionEtablissement(data)
      return res
    },
    async rejectInscriptionEtablissement({ rootState }, data) {
      const res = await etablissementsService.rejectInscriptionEtablissement(data)
      return res
    },
    async updateEtablissement({ rootState }, data) {
      const res = await etablissementsService.updateEtablissement(data)
      return res
    },
    async updateFileEtablissement({ rootState }, data) {
      const res = await etablissementsService.updateFileEtablissement(data)
      return res
    },
    async createAgent({ rootState }, data) {
      const res = await etablissementsService.createAgent(data)
      return res
    },
    async getAgent({ rootState }, data) {
      const res = await etablissementsService.getAgent(data)
      return res
    },
    async updateAgent({ rootState }, data) {
      const res = await etablissementsService.updateAgent(data)
      return res
    },
    async archiverAgent({ rootState }, data) {
      const res = await etablissementsService.archiverAgent(data)
      return res
    },
  },
}

import { BaseService } from './base.service'

export class EtablissementsService extends BaseService {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super()
  }

  // eslint-disable-next-line no-unused-vars
  fetchEtablissements(data) {
    const params = {}
    if (data && data.options) {
      params.options = data.options
    }
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/inscriptions/list', { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  exportEtablissements({ formType, params }) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/inscriptions/export', { params, responseType: 'blob' })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  findEtablissement(data) {
    const { params } = data || {}

    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/inscriptions/list', { params: params || {} })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  getEtablissement(id) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/inscriptions/${id}`, {})
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  getAgent(id) {
    // console.log('--- id: --', id)
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/admins/${id}`, {})
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  getListTypesEtablissement() {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/types-etablissements/list', {})
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  createEtablissement(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/inscriptions/create-file', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  verifyEtablissementEmail(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/admins/confirm-email', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  validateInscriptionEtablissement(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/admins/validate-preinscription', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  rejectInscriptionEtablissement(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/admins/reject-preinscription', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  updateEtablissement({ id, data }) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .put(`/inscriptions/${id}`, data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  updateFileEtablissement({ id, data }) {
    // console.log('id, data::: ', id, data);
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .put(`/inscriptions/update-file/${id}`, data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  createAgent(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/inscriptions/register-agent', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  updateAgent(object) {
    return new Promise((resolve, reject) => {
      const { id, data } = object
      // console.log('========== :', id, ' ======== ', data)
      this.axiosInstance
        .put(`/admins/${id}`, data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  archiverAgent(data) {
    console.log(data)
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .delete('inscriptions/archive-agent', { data })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

export default new EtablissementsService()
